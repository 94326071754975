/* eslint-disable sort-keys-fix/sort-keys-fix */
import { AppConfig } from './types';
import { defaultConfig } from './default';
import {
  boostConfig,
  defaultRoyalpayConfig,
  inksoftConfig,
  payrixConfig,
  printavoConfig,
} from './royalpay';

const configByBrandId: Record<string, AppConfig> = {
  default: defaultConfig,
  inksoft: inksoftConfig,
  printavo: printavoConfig,

  '4a2e5a3d-380d-41c3-8e25-0ca596 0660c1': boostConfig, // dev
  '7e977d35-922b-42c4-8236-0c9c04a64d8a': boostConfig, // test
  '36de326f-f189-4c98-b10f-48fe9e699227': boostConfig, // stage

  'd786e96f-4a4c-43ee-b474-e103d487c5b4': payrixConfig, // test
  'b65e31f2-efea-44a1-abf5-e129c98fbbb4': payrixConfig, // stage

  'bff6ba8f-066a-412d-b284-f0ed9a39ee3c': defaultRoyalpayConfig, // prod
};

export const getAppConfig = (brandId: string): AppConfig =>
  configByBrandId[brandId] ?? configByBrandId.default;
